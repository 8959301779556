@import url(https://fonts.googleapis.com/css?family=Lato);
@import url(https://fonts.googleapis.com/css?family=Playfair+Display);
* {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  outline: inherit;
  color: inherit;
  text-decoration: inherit;
}

blockquote {
  background-color: #f5f6fa;
  font-style: italic;
  padding: 0.5rem 1.25rem;
  border-left: 0.5rem solid #eaecf4;
  margin-left: 1rem;
  margin-right: 1rem;
}

blockquote > p {
  margin: 0;
}

html, body, #root {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
}

